import gql from 'graphql-tag'
import chatFragment from './ChatFragment'

export const CHATS_QUERY = gql `
  query getChatMessage($room: String) {
    chats: getChatMessage(room: $room) {
      ...${chatFragment}
    }
  }
`

export const CHATS_QUERY_OUT = gql`
  query getChatMessageSuport_A($room: String) {
    chats: getChatMessageSuport_A(room: $room) {
      ...${chatFragment}
    }
  }
`



export const CHATS_ROOM_QUERY = gql `
  query getReciveMessageEntity {
    recivedChats: getReciveMessageEntity {
      room :name
      testID: test
      flag
      messages{
        body
        time
        read
        date
        flag_to
        
        author {
          id
          name
          photo
          type
        }

        to{
          id
          name
          photo
          type
        }
      }
    }
  }
`

export const CHECK_CHAT_SUPORT = gql `
  query checkSuportMessage($id: ID) {
    checkSuportMessage(id:$id) {
      flag
    }
  }
`

export const CHATS_QUERY_OUT_LIST = gql`
  query getReciveMessageSuportEntity_A($id: ID) {
    chatsWL: getReciveMessageSuportEntity_A(id: $id){
       room :name
      testID: test
      from
      messages{
        body
        time
        read
        date
        flag_to
        author{
          id
          name
          photo
        }
        to{
          id
          name
          photo
        }
      }
    }
  }
`
export const CHATS_ROOM_QUERY_UPD = gql`
  query getReciveMessageSuport_A {
    getReciveMessageSuport_A {
      room :name
      testID: test
      from
      messages{
        body
        time
        read
        date
        flag_to
        author{
          id
          name
          photo
        }
      }
    }
  }
`