import gql from 'graphql-tag'
import testFagment from './TestFragment'

export const CREATE_TEST_MUTATION = gql`
  mutation CreateTest($testInput: TestInput) {
    createTest(testInput: $testInput) {
      ...${testFagment}
    }
  }
`

export const SEND_TESTS_IMPORTED_MUTATION = gql`
  mutation SendTestsImported(
    $candidates: [CandidateInput], 
    $date: String, 
    $tests: [TestInput], 
    $time: String, 
    $vacancyId: ID, 
    $vacancyTitle: String
  ) {
    sendTestsImported(
      candidates: $candidates, 
      date: $date, 
      tests: $tests, 
      time: $time, 
      vacancyId: $vacancyId, 
      vacancyTitle: $vacancyTitle
    )
  }
`

export const UPDATE_TEST_MUTATION = gql`
  mutation UpdateTest($testInput: TestInput) {
    updateTest(testInput: $testInput) {
      ...${testFagment}
    }
  }
`

export const DELETE_TEST_MUTATION = gql`
  mutation DeleteTest($id: ID!) {
    deleteTest(id: $id) {
      id
    }
  }
`