var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}]},[_c('v-card',{staticClass:"sc-chat-window",attrs:{"border":""}},[_c('v-toolbar',{attrs:{"color":"primary","light":"","dark":"","dense":""}},[_c('v-avatar',{attrs:{"size":"36"}},[_c('img',{attrs:{"src":"/avatar-whire.png"}})]),_c('v-toolbar-title',{staticClass:"mx-2"},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-transform":"uppercase","font-size":"10pt"}},[_vm._v(_vm._s(_vm.$t("TALK")))])],1)],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"d-flex flex-row justify-end mt-2 px-3"},[_c('v-btn',{staticClass:"btn",attrs:{"text":""},on:{"click":_vm.back}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12"}},[_c('p')])],1),_c('div',{ref:"scrollList",staticClass:"sc-message-list",staticStyle:{"background":"#f2f2f2"}},[_c('div',{staticClass:"chats"},[_vm._l((_vm.messages),function(message,index){return _c('div',{key:index},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[(message.date)?_c('v-subheader',{attrs:{"inset":""}},[_vm._v(" "+_vm._s(message.date)+" ")]):_vm._e(),(message.date)?_c('v-divider'):_vm._e()],1),_c('v-row',{staticStyle:{"margin-bottom":"-13%"}},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"11"}},[(message.author.id !== _vm.currentUser.id)?_c('span',{staticStyle:{"font-size":"10pt"}},[_vm._v(" "+_vm._s(_vm.getUserName(message.author.name))+" ")]):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[(message.author.id !== _vm.currentUser.id)?_c('v-avatar',{attrs:{"size":"26"}},[_c('v-img',{attrs:{"src":message.author.photo
                            ? message.author.type === 'entity'
                              ? (_vm.apiUrl + "/images/user/" + (message.author.photo))
                              : (_vm.apiUrl + "/images/partner/" + (message.author.photo))
                            : '/profile.png'}})],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-row',{staticClass:"time"},[(
                          message.author.id === _vm.currentUser.id &&
                            index != _vm.messages.length - 1
                        )?_c('span',{staticClass:"message-time-in"},[_c('span',{staticStyle:{"margin-left":"72%","font-size":"9pt"}},[_vm._v(" Eu")])]):_vm._e(),(
                          message.author.id === _vm.currentUser.id &&
                            index === _vm.messages.length - 1
                        )?_c('span',{staticClass:"message-time-in"},[_c('span',{staticStyle:{"margin-left":"72%","font-size":"9pt"}},[_vm._v(" Eu")])]):_vm._e()]),_c('p',{staticClass:"message",class:{
                        'message-out': message.author.id === _vm.currentUser.id,
                        'message-in': message.author.id !== _vm.currentUser.id,
                      }},[_vm._v(" "+_vm._s(message.body)+" ")]),_c('v-row',{staticClass:"time"},[(message.author.id !== _vm.currentUser.id)?_c('span',{staticClass:"message-time-out"},[_vm._v(_vm._s(message.time))]):_vm._e(),(
                          message.author.id === _vm.currentUser.id &&
                            index != _vm.messages.length - 1
                        )?_c('span',{staticClass:"message-time-in"},[_vm._v(_vm._s(message.time)+" ")]):_vm._e(),(
                          message.author.id === _vm.currentUser.id &&
                            index === _vm.messages.length - 1
                        )?_c('span',{staticClass:"message-time-in"},[_vm._v(_vm._s(_vm.sendFlag ? message.time : _vm.$t("sending"))+" ")]):_vm._e()])],1)],1)],1)],1)],1)})],2)]),_c('v-container',{staticStyle:{"background":"#f2f2f2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"prepend-inner-icon":_vm.icon,"solo":"","disabled":_vm.loader,"clear-icon":"mdi-send","clearable":"","label":_vm.$t('typemessage'),"type":"text","rows":"2"},on:{"click:append-outer":_vm.sendMessage,"click:clear":_vm.sendMessage},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }